import { PaymentMethodViewModel } from './backend/models/payment-method-view-model';
import moment from 'moment';
import { getReverseGeolocation } from './api';
import {
  AppPromotionCodeViewModel,
  FileCarouselItemViewModel,
  GigTicketViewModel,
  GigViewModel,
  MediaFileThumbnailViewModel,
  ReviewViewModel
} from './backend/models';
import {
  GRAMMY_ADMINS,
  GRAMMY_CHECKIN_USERS,
  GRAMMY_USERS,
  PRIVATE_EVENT_MSG
} from './consts';
import {
  Address,
  AddressFields,
  AdressComponent,
  BrandObject,
  EventsWithStatus,
  FormattedTicketStats,
  ICoordinates,
  IRatings
} from './types';
import dayjs from 'dayjs';
import ExperienceModel from './models/ExperienceModel';
import { EventResponse } from './api/models';
import { HostingType } from './backend/enums';
import { ProfileTabsEnum } from './enums';

export const isEmail =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

export const isValidURL =
  /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?(\?.*)?$/;

export function iOS(): boolean {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isTabletDevie(): boolean {
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    navigator.userAgent
  );
}

export function isMobileDevice(): boolean {
  const mobileDeviceRegex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobileDeviceRegex.test(navigator.userAgent);
}

export function isAppleDevice(): Boolean {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
}

export function isAndroid(): Boolean {
  return /Android/.test(navigator.userAgent);
}

export function isSafariBrowser() {
  return (
    navigator.userAgent.indexOf('Safari') > -1 &&
    navigator.userAgent.indexOf('Chrome') === -1
  );
}

export function isChromeBrowser() {
  return navigator.userAgent.match(/chrome|chromium|crios/i);
}

export function isInstagramInAppBrowser() {
  return navigator.userAgent.match(/instagram/i);
}

export const getCurrentLocationPermission = async () => {
  return await navigator.permissions.query({ name: 'geolocation' });
};

export function getCurrentCoords(
  onSuccess: (coords: ICoordinates) => void,
  onError: (
    errorMessage: string,
    GeolocationError?: GeolocationPositionError
  ) => void
) {
  if (!navigator.geolocation) {
    onError('Geolocation is not supported by your browser');
    return;
  }

  function success(position: GeolocationPosition) {
    onSuccess({
      lat: position.coords.latitude,
      lng: position.coords.longitude
    });
  }

  function error(e: GeolocationPositionError) {
    onError(
      e.code === 1
        ? 'Location permission has been denied'
        : 'Something went wrong while getting you location',
      e
    );
  }

  navigator.geolocation.getCurrentPosition(success, error, {
    enableHighAccuracy: true,
    timeout: 7000,
    maximumAge: 0
  });
}

export const getLatLngFromAddress = async (address: string) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  const response = await fetch(url);
  const result = await response.json();

  return result;
};

export const formatCustomDate = (dateInMilliseconds: number) => {
  return moment(dateInMilliseconds).format('MMM D, YYYY');
};

export function calculateDistanceInMiles(
  coord1: ICoordinates,
  coord2: ICoordinates
): number {
  function toRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }

  const R = 3958.8; // Earth's radius in miles
  const dLat = toRadians(coord2.lat - coord1.lat);
  const dLon = toRadians(coord2.lng - coord1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(coord1.lat)) *
      Math.cos(toRadians(coord2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
}

// export const distanceBetween = (
//   latLong1: ICoordinates,
//   latLong2: ICoordinates
// ) => {
//   const earthRadiusKm = 6371;
//   const kmInMi = 1.609344;

//   function degreesToRadians(degrees: number) {
//     return (degrees * Math.PI) / 180;
//   }

//   const dLat = degreesToRadians(latLong2.lat - latLong1.lat);
//   const dLon = degreesToRadians(latLong2.lng - latLong1.lng);

//   const lat1 = degreesToRadians(latLong1.lat);
//   const lat2 = degreesToRadians(latLong2.lat);

//   const a =
//     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//     Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

//   const distanceInKm = earthRadiusKm * c;
//   return distanceInKm / kmInMi; //Convert it to miles and return
// };

export const averageRating = (reviews: ReviewViewModel[]) => {
  if (!reviews.length) return 0;

  const ratings = reviews.reduce((acc: IRatings, item) => {
    if (item.creatorProRate in acc) acc[item.creatorProRate] += 1;
    else acc[item.creatorProRate] = 1;

    return acc;
  }, {});

  let count = 0;

  const sum = Object.entries(ratings).reduce((acc, item, idx) => {
    count += item[1];
    return acc + item[1] * Number(item[0]);
  }, 0);

  return (sum / count).toFixed(1);
};

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const getRandomString = () => {
  return Math.random().toString(36).substr(2, 8);
};

export const removeMentionsInText = (content: string) => {
  return content ? content.replace(/\@[a-z0-9-]+/g, '') : null;
};

export const validateDeepLinkAndExecute = (deepLink: string) => {
  if (
    !iOS() &&
    (deepLink.startsWith('hooplaApp://') ||
      deepLink.startsWith('hooplaAppDev://'))
  )
    document.location.href = deepLink;
};

export const numFormatter = (num: number) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K';
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else {
    return num;
  }
};

export const diffHours = (date1: Date, date2: Date) => {
  let diff = (date1.getTime() - date2.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(diff);
};

export const convertMillisToHourAndMinFormat = (milliseconds: number) => {
  const hours = Math.floor(milliseconds / 1000 / 60 / 60);
  const mins = Math.floor((milliseconds / 1000 / 60) % 60);

  const hoursDisplay = `${hours} hour${hours !== 1 ? 's' : ''}`;
  const minsDisplay = mins > 0 ? `${mins} minute${mins !== 1 ? 's' : ''}` : '';

  return `${hoursDisplay} ${minsDisplay}`.trim();
};

export const calculatePercentageOf = (amount: number, percentage: number) => {
  return (amount * percentage) / 100;
};

export const calculatePercentageOfValue = (
  value: number,
  totalValue: number
) => {
  return (value / totalValue) * 100;
};

export const convertToCurrency = (value: number | string) => {
  const _value = typeof value === 'string' ? parseFloat(value) : value;
  return _value.toLocaleString('en-US', {
    style: 'currency',
    maximumFractionDigits: 2,
    currency: 'USD'
  });
};

export const calculateDiscount = (
  price: number,
  coupon: AppPromotionCodeViewModel
) => {
  return coupon?.type === 'Amount'
    ? coupon.value
    : calculatePercentageOf(price, coupon?.value || 0);
};

export const createBrandObject = (brandName: string): BrandObject => {
  switch (brandName) {
    case 'card':
      return {
        brandName: 'Credit/Debit Card',
        brandLogo: null
      };
    case 'mastercard':
      return {
        brandName: 'Mastercard',
        brandLogo: '/payments/payment_mc@3x.png'
      };
    case 'visa':
      return {
        brandName: 'Visa',
        brandLogo: '/payments/payment_visa@3x.png'
      };
    case 'amex':
      return {
        brandName: 'AMEX',
        brandLogo: '/payments/payment_amex.png'
      };
    case 'diners':
      return {
        brandName: 'Diners',
        brandLogo: '/payments/payment_diner.png'
      };
    case 'discover':
      return {
        brandName: 'Discover',
        brandLogo: '/payments/payment_discover.png'
      };
    case 'googlepay':
      return {
        brandName: '',
        brandLogo: '/payments/google_pay.svg'
      };
    case 'applepay':
      return {
        brandName: '',
        brandLogo: '/payments/apple_pay.svg'
      };
    case 'jcb':
      return {
        brandName: 'JCB',
        brandLogo: '/payments/payment_jcb.png'
      };
    case 'union':
      return {
        brandName: 'Union',
        brandLogo: '/payments/payment_union.png'
      };

    default:
      return {
        brandName: 'Visa',
        brandLogo: '/payments/payment_visa@3x.png'
      };
  }
};

export const getPaymentMethodInfo = (paymentMethod: PaymentMethodViewModel) => {
  let brandName,
    brandLogo,
    brandLabel = undefined;

  if (paymentMethod) {
    const brand = createBrandObject(paymentMethod.brand);

    brandName = brand?.brandName;
    brandLogo = brand?.brandLogo;

    brandLabel = ['applepay', 'googlepay', 'card'].includes(paymentMethod.brand)
      ? brandName
      : `${brandName} ending in ${paymentMethod.last4}`;
  }

  return {
    brandName,
    brandLogo,
    brandLabel
  };
};

export const extractAddressFields = (location: any): Address => {
  const premise = location.address_components?.find((c: AdressComponent) =>
    c.types.includes('premise')
  )?.long_name;
  const city = location.address_components?.find((c: AdressComponent) =>
    c.types.includes('locality')
  )?.long_name;
  const streetNum = location.address_components?.find((c: AdressComponent) =>
    c.types.includes('street_number')
  )?.long_name;
  const street = location.address_components?.find((c: AdressComponent) =>
    c.types.includes('route')
  )?.short_name;
  const zip = location.address_components?.find((c: AdressComponent) =>
    c.types.includes('postal_code')
  )?.long_name;
  const state = location.address_components?.find((c: AdressComponent) =>
    c.types.includes('administrative_area_level_1')
  )?.short_name;
  const country = location.address_components?.find((c: AdressComponent) =>
    c.types.includes('country')
  )?.short_name;

  let address = streetNum ?? '';
  address += street ? ' ' + street : '';

  return {
    address: address,
    address2: premise,
    city,
    state,
    zip
  };
};

export const formatAddressFromResult = (addressResult: any) => {
  const { city, state, zip, address } = extractAddressFields(addressResult);
  const formattedAddress = [address, city, state, zip]
    .filter(Boolean)
    .join(', ');

  return formattedAddress;
};

export const generateFormattedAddress = async (latLng: ICoordinates) => {
  const res = await getReverseGeolocation(latLng.lat, latLng.lng);
  const result = res.results[0];

  if (!result) return;

  return {
    latLng,
    addressResult: extractAddressFields(result),
    formattedAddress: formatAddressFromResult(result)
  };
};

export const determineWhichLocationToUse = (
  experience: ExperienceModel,
  userLocation: Address
) => {
  return experience.isOneTimeEvent ||
    (experience.isCreatorLocation && experience.hasCoords) ||
    (experience.isCustomerLocation && !experience.IsAreaFlexible)
    ? experience.location
    : (experience.isFlexibleLocation && !experience.isVirtual) ||
      (experience.isCustomerLocation && experience.IsAreaFlexible)
    ? userLocation
    : null;
};

export const generateFullName = () => {
  const _date = new Date();
  return `User ${_date.getSeconds()}${_date.getMinutes()}${_date.getMonth()}${_date.getFullYear()}${_date.getDay()}${_date.getHours()}`;
};

export const generateUsename = (fullName: string) => {
  const _date = new Date();
  return fullName ? fullName.replaceAll(' ', '_').toLowerCase() : null;
};

export const formatUrl = (url: string) => {
  const _url = !url.startsWith('http') ? 'http://' + url : url;
  const splittedUrl = _url.split('?');
  let cleanUrl = splittedUrl[0].replace(/https?\:\/\//i, '');

  return {
    cleanUrl,
    formattedUrl: _url,
    originalUrl: url
  };
};

export const generatePrivateMessage = (isOneTimeEvent: boolean) => {
  const type = isOneTimeEvent ? 'Event' : 'Experience';
  return PRIVATE_EVENT_MSG.replace('%EXP_TYPE%', type);
};

export const addTimeOffsetToBookingDate = (date: Date) => {
  const minutes = date.getMinutes();

  const offset =
    minutes > 0 && minutes < 15
      ? 15
      : minutes > 15 && minutes < 30
      ? 30
      : minutes > 30 && minutes < 45
      ? 45
      : minutes > 45
      ? 0
      : minutes;

  if (minutes > 45) {
    date.setHours(date.getHours() + 1);
    date.setMinutes(0);
  } else {
    date.setMinutes(offset);
  }

  date.setDate(date.getDate() + 1);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

export const addEndOfTheDateTimeOffset = (date: Date): Date => {
  return dayjs(date)
    .set('hours', 23)
    .set('minutes', 59)
    .set('seconds', 59)
    .toDate();
};

export const downloadExcelFile = (file: Blob, newFilename: string) => {
  const blob = new Blob([file], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `${newFilename}.xlsx`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const convertDateToLocalUsingTimezone = (
  dateInSeconds: number,
  timezoneOffset: number
) => {
  const fixedDate = dayjs
    .utc(dateInSeconds * 1000)
    .utcOffset(timezoneOffset / 60 / 60);

  const newDate = new Date();
  newDate.setFullYear(
    fixedDate.get('year'),
    fixedDate.get('month'),
    fixedDate.get('date')
  );

  newDate.setHours(fixedDate.get('hour'), fixedDate.get('minutes'), 0, 0);

  return newDate;
};

export const getGrammyUserAccessPermission = (email: string) => {
  const isAdmin = GRAMMY_ADMINS.includes(email);
  if (isAdmin) return 'grammy_admin';

  const isUser = GRAMMY_USERS.includes(email);
  if (isUser) return 'grammy_user';

  const isCheckInUser = GRAMMY_CHECKIN_USERS.includes(email);
  if (isCheckInUser) return 'grammy_checkin';
};

export const isValidGrammyUser = (email: string) => {
  return [...GRAMMY_ADMINS, ...GRAMMY_USERS, ...GRAMMY_CHECKIN_USERS].includes(
    email
  );
};

export const convertEventModelToGigViewModel = (
  event: EventResponse
): GigViewModel => {
  const mediaFilesThumbs: MediaFileThumbnailViewModel = {
    mobile: {},
    web: null
  };

  if (event.thumbnailImagePath) {
    mediaFilesThumbs.web = [event.thumbnailImagePath];
    mediaFilesThumbs.mobile.static = [event.thumbnailImagePath];
  }

  if (event.thumbnailVideoPath || event.thumbnailGifPath) {
    mediaFilesThumbs.mobile.animated = [
      event.thumbnailVideoPath,
      event.thumbnailGifPath
    ].filter(Boolean);
  }

  if (event.thumbnailVideoPath) {
    mediaFilesThumbs.mobile.video = event.thumbnailVideoPath;
  }

  const gigMainMediaFiles = event.mediaFiles.map<FileCarouselItemViewModel>(
    (mediaFile, idx) => ({
      ...mediaFile,
      order: idx,
      thumbnails: mediaFilesThumbs
    })
  );

  const address = event.fullAddress.split(',');
  let city,
    state = null;

  if (event.hostingType !== HostingType.Virtual) {
    const address = event.fullAddress.split(',');
    city = address[0];
    state = address[1].trim();
  }

  return {
    id: event.id,
    name: event.name,
    description: event.description,
    includedInfo: event.includedInfo,
    toBringInfo: event.toBringInfo,
    location: event.locationName,
    locationName: event.locationName,
    address: event.address,
    address2: event.address2,
    city,
    state,
    zip: event.zip,
    oneEventTimeTimestampFromSeconds: event.startDateEpochSeconds,
    oneEventTimeTimestampToSeconds: event.endDateEpochSeconds,
    oneEventTimeDuration: event.durationSeconds,
    hostingType: event.hostingType,
    pricingModel: event.pricingModel,
    timeZoneAbbreviation: event.timeZoneAbbreviation,
    timeZoneId: event.timeZoneId,
    timeZoneOffsetInSeconds: event.timeZoneOffsetInSeconds,
    tickets: event.tickets.map((ticket, idx) => ({ id: idx, price: ticket })),
    price: event.price,
    priceBaseDiscount: event.priceBaseDiscount,
    isPrivateExperience: false,
    isRecurrentEvent: event.isRecurrent,
    isClosed: event.isClosed,
    isSoldOut: event.isSoldOut,
    isServiceAreaFlexible: false,
    oneEventTime: true,
    viewedTimes: event.totalViews,
    likes: event.totalLikes,
    provider: {
      id: event.userId,
      userName: event.userName,
      fullName: event.userFullName,
      image: event.userImage,
      isCreatorPro: event.isCreatorPro,
      isProviderPro: false
    },
    additionalData: {
      ticketTypeOption: event.ticketTypeOption,
      ticketExternalLink: event.ticketExternalLink,
      showWhoHasBook: event?.showWhoHasBook ?? false,
      invitationMethod: null
    },
    gigMainMediaFiles
  };
};

export const convertGigModelToEventsWithStatus = (
  events: GigViewModel[]
): EventsWithStatus => {
  const _events = events
    .filter((event) => event.oneEventTime)
    .map((event) => new ExperienceModel(event))
    .sort((a, b) => a.eventStartDate - b.eventStartDate);

  const upcoming = _events.filter((event) => !event.isEventClosed);
  const past = _events.filter((event) => event.isEventClosed);
  const cancelled: ExperienceModel[] = [];

  return {
    [ProfileTabsEnum.Upcoming]: upcoming,
    [ProfileTabsEnum.Past]: past,
    [ProfileTabsEnum.Cancelled]: cancelled
  };
};
